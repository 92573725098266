.instruction-page {
  &__text {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.33px;
    text-align: left;

    h4 {
      margin: 20px 0;
    }

    p:not(:last-child) {
      margin-bottom: 10px;
    }

    a {
      color: inherit;

      &:hover {
        text-decoration: underline;
      }

      &.youtube-link {
        display: block;
        margin-top: 10px;
        color: #0000EE;
      }
    }
  }
}