@keyframes selectionUp {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

.profile-page,
.settings-page,
.solution-page {
    position: relative;
    padding-bottom: 0;
    background-image: $gradient-blue;

    &__alarm,
    &__helpline {
        padding: 1rem;
        display: block;
        cursor: pointer;
        position: relative;
        border-radius: 16px;
        margin: 1.5rem 1.5rem 0;
        background: $gradient-blue;

        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0.4px;
        color: #fff;

        p:last-child {
            font-size: 14px;
            letter-spacing: 0.2625px;
            color: rgba(255, 255, 255, 0.6);
        }

        &::after {
            top: 1rem;
            right: 1rem;
            content: "";
            width: 44px;
            height: 44px;
            border-radius: 50%;
            position: absolute;
            background-size: auto 12px;
            background-position: center;
            background-repeat: no-repeat;
            background-color: rgba(255, 255, 255, 0.2);
            background-image: url(../img/ic-arrow-next.svg);
        }

        &_disabled {
            cursor: initial;
            color: #122443;

            background: #fff;
            box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.1);

            p:last-child {
                color: rgba(18, 36, 67, 0.4);
            }

            &::after {
                background-size: 12px auto;
                background-color: rgba(82, 194, 43, 0.16);
                background-image: url(../img/ic-check-green.svg);
            }
        }
    }

    &__helpline {
        margin-top: 12px;
        cursor: auto;

        &::after {
            content: none;
        }
    }

    &__psych-alarm {
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        border-radius: 16px;
        margin: 1.5rem 1.5rem 0;
        background: #fff;

        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0.4px;
        color: #122443;

        box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.1);

        p:last-child {
            color: rgba(18, 36, 67, 0.6);
        }

        span {
            width: 44px;
            height: 44px;
            display: flex;
            position: relative;
            align-items: center;
            justify-content: center;

            font-size: 18px;
            line-height: 26px;
            font-weight: bold;
            letter-spacing: 0.3375px;
            color: rgba(18, 36, 67, 0.3);

            margin-left: 1rem;
            border-radius: 50%;
            background-color: rgba(18, 36, 67, 0.04);
        }

        &_notice {
            span {
                color: #fff;
                background: $gradient-red;
                // &::before {
                //     top: 4px;
                //     right: 4px;
                //     width: 5px;
                //     height: 5px;
                //     content: "";
                //     position: absolute;
                //     border-radius: 50%;
                //     background-color: rgba(235, 51, 73, 1);
                // }
            }
        }
    }

    &__main {
        padding-top: 5rem;
        margin: -5rem -24px 0;
        padding-bottom: 2.5rem;
    }

    &__title {
        color: #ffffff;
    }

    &__info {
        margin-bottom: 1rem;
    }

    &__name {
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.4px;
        color: #ffffff;
    }

    &__birthday {
        margin-top: 4px;
        opacity: 0.8;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.2625px;
        color: #ffffff;
    }

    &__schedule {
        display: inline-block;
        max-width: 350px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__contacts {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    &__btn {
        color: #122443;
        font-size: 14px;
        line-height: 22px;
        padding: 6px 12px;
        border-radius: 7px;
        background: #fff;
        box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.1);
    }

    &__entities {
        flex-grow: 1;
        background: #fff;
        border-radius: 20px 20px 0 0;
        margin: -1rem -1.5rem 0;

        &-wrapper {
            display: grid;
            grid-gap: 12px;
            padding: 1.5rem 1.5rem 2.5rem;
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

.entity {
    cursor: pointer;
    position: relative;
    padding: 1rem;
    min-height: 163px;
    border-radius: 10px;
    background: linear-gradient(0deg, #f4f7f9, #f4f7f9);

    &_school {
        background: $gradient-blue;
    }

    &_test {
        background: $gradient-red;
    }

    &_statistic {
        background: $gradient-orange;
    }

    &_advice {
        background: $gradient-green;
    }
    &_workWithMethods {
        background: $gradient-green;
    }
    &_riskGroups {
        background: $gradient-red;
    }

    &_meeting {
        background: $gradient-purple;
    }

    &_note {
        background: $gradient-yellow;
    }

    &__title {
        color: #122443;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.3px;

        &_white {
            color: #fff;
        }
    }

    &__description {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.3px;
        color: #122443;
        opacity: 0.4;

        &_white {
            color: #fff;
            opacity: 1;
        }
    }

    &_instruction {
        background: $gradient-blue;

        &__desc {
            margin-top: 10px;
            color: white;
            opacity: 0.9;
            font-weight: 500;
        }
    }

    &__extra {
        left: 1rem;
        bottom: 1rem;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 2.5rem;
        height: 2.5rem;
        margin-top: auto;
        border-radius: 100%;
        background: rgba(255, 255, 255, 0.16);

        color: #fff;
        font-size: 18px;
        font-weight: bold;
        line-height: 26px;
        text-align: center;
        letter-spacing: 0.3px;

        &_statistic {
            &::after {
                content: "";
                width: 100%;
                height: 100%;
                background: url(../img/ic-chart.svg) no-repeat center;
            }
        }
        &_workWithMethods {
            &::after {
                content: "";
                width: 100%;
                height: 100%;
                background: url(../img/ic-chartHoriz.svg) no-repeat center;
            }
        }
        &_instruction {
            &::after {
                content: "";
                width: 100%;
                height: 100%;
                background: url(../img/ic-info.svg) no-repeat center;
            }
        }

        &_psych-null {
            background: #ffffff;
            box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.24);
            &::after {
                content: "";
                width: 100%;
                height: 100%;
                background: url(../img/ic-plus.svg) no-repeat center;
            }
        }
        &_pupil-activeMeeting{
            color: white;
            background: #ff2525;
        }
        &_pupil-null {
            color: rgba(18, 36, 67, 0.3);
            background-color: rgba(18, 36, 67, 0.04);
        }
    }
}
