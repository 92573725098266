.workWithMethods-page {
  //&__wrapper {
  //  flex-grow: 1;
  //  display: flex;
  //  flex-flow: column;
  //}

  &__list a:not(:first-child) {
    margin-top: 20px;
  }
}