.scale-desc{
  h3{
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.5px;
    text-align: left;
    margin-bottom: 7px;
  }
  h5{
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.23333333432674408px;
    text-align: left;
    opacity: 0.4;
    margin-bottom: 20px;
  }
  h4{
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.5px;
    text-align: left;
    margin: 0;
  }
  p{
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.26249998807907104px;
    text-align: left;
    opacity: 0.4;
    margin-bottom: 17px;
  }
  &__levels{
    margin-top: 12px;
  }
  &__level{
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
    background-color: white;
    margin-bottom: 12px;
    border-radius: 16px;

    p{
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.23333333432674408px;
      text-align: left;
      opacity: 1;
      margin: 0;

      span{
        opacity: 0.4;
      }
    }
  }

}
.questions-list{
  display: flex;
  flex-direction: column;
  width: 100%;

  &__item{
    width: 100%;
    padding: 16px;
    background-color: white;
    border-radius: 16px;
    margin-bottom: 10px;

    & > p{
      margin-bottom: 4px;
    }

    label{
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.26249998807907104px;
      text-align: left;
      opacity: 0.4;
    }

    .number-holder{
      background: linear-gradient(270deg, #EB3349 0%, #F45C43 100%);
      border-radius: 5px;
      width: max-content;
      padding: 0px 8px;
      margin-bottom: 13px;

      p{
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.20000000298023224px;
        text-align: left;
        color: white;
      }
    }
  }
}